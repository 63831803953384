import { addSplideClasses, connectSplideArrows, connectSplideBullets, connectSplideCarouselBullets, sel, selAll, splideAutoWidth } from './utils'
import '@splidejs/splide/css'
import Splide from '@splidejs/splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'

import { Intersection } from '@splidejs/splide-extension-intersection'

export default function Study() {
  function galleryInit() {
    const name = 'study'
    addSplideClasses(name + '__slider')
    const splide = new Splide('.' + name + '__slider', {
      // perPage: 1,
      // width: '100%',
      autoplay: true,
      // autoHeight: true,
      type: 'fade',
      rewind: true,
      pagination: false,
      arrows: false,
      padding: {
        // right: '1rem',
      },
      breakpoints: {
        768: {
          padding: {
            right: '0',
          },
        },
      },
    })

    addSplideClasses(name + '__thumb-slider')
    const thumbnails = new Splide('.' + name + '__thumb-slider', {
      gap: '1rem',
      // autoplay: true,
      perPage: 5,
      pagination: false,
      arrows: false,
      isNavigation: true,
      speed: 1500,
      interval: 3000,
      easing: 'cubic-bezier(0.16, 1, 0.3, 1)',
      breakpoints: {
        478: {
          perPage: 2,
          gap: '0.75rem',
        },
      },
    })
    splide.sync(thumbnails)
    splide.mount()
    thumbnails.mount()
    // connectSplideBullets(splide, '.bullets.is--os')
    // connectSplideArrows(splide, 'arrows', '.round-arrow')
  }
  galleryInit()
}
